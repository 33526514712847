<template>
  <v-container fluid>
    <v-form ref="fishCondition">
      <v-row>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="SOURCE"
            :items="sources"
            :rules="rule"
            v-model="source"
            :open-on-clear="false"
            :disabled="sources.length < 2"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="Species"
            :items="species"
            v-model="specie"
            item-text="name"
            item-value="acronym"
            :rules="rule"
            no-filter
            :search-input.sync="searchSpecies"
            @click:clear="clearChart"
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="YEAR"
            :items="years"
            :rules="rule"
            v-model="year"
            :open-on-clear="false"
            hide-selected
            hide-details
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn block color="primary" @click="getReport" :loading="loading"
            >Apply</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <div v-if="chartData.labels.length">
      <pie-chart :chart-data="chartData" :options="options" />
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import PieChart from './PieChart.vue';
import { SOURCE_TYPES } from '../../helpers/constants';
export default {
  components: { PieChart },
  name: 'fish-condition',
  data: () => ({
    species: [],
    specie: 'ALL',
    source: 'TBF',
    chartData: { labels: [], datasets: [] },
    options: null,
    search: null,
    year: 'ALL',
    rule: [(v) => !!v || 'Required'],
    loading: false
  }),
  mounted() {
    this.getSpecies();
  },
  computed: {
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    searchSpecies: {
      get: function () {
        return this.search;
      },
      set: function (v) {
        if (v !== this.search) {
          this.search = v;
          this.getSpecies(v);
        }
      }
    },
    years() {
      const years = [];
      for (let year = 2012; year <= moment().year(); year++) {
        years.push(year);
      }
      years.push('ALL');
      return years.reverse();
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    ...mapActions('analytic', ['getFishCondition']),
    clearChart() {
      this.chartData = { labels: [], datasets: [] };
      this.options = null;
    },
    async getReport() {
      const valid =
        this.$refs.fishCondition && this.$refs.fishCondition.validate();
      if (valid) {
        this.loading = true;
        const response = await this.getFishCondition({
          specie: this.specie,
          year: this.year,
          source: this.source
        });
        const recaptures = response?.data?.docs?.recaptures;

        if (recaptures) {
          let total = 0;
          const data = Object.values(recaptures).map((v) => {
            total += Number(v);
            return v;
          });

          const labels = Object.keys(recaptures).map((v) => {
            const percent = (recaptures[v] * 100) / total;
            return `${v} (${recaptures[v]} - ${Number(percent).toFixed(2)}%)`;
          });

          this.chartData = {
            labels,
            datasets: [
              {
                label: ' Fish Condition',
                backgroundColor: [
                  '#2196F3',
                  '#4CAF50',
                  '#FF9800',
                  '#9C27B0',
                  '#009688',
                  '#CDDC39'
                ],
                data
              }
            ]
          };

          this.options = {
            responsive: true,
            maintainAspectRatio: false
          };
        } else this.clearChart();

        this.loading = false;
      }
    },
    async getSpecies(name = '') {
      const payload = { name, item: 'species', page: 1, limit: 100 };
      const response = await this.getItems(payload);
      const all = { name: 'ALL', acronym: 'ALL' };
      if (response?.data?.docs) {
        this.species = [all];
        this.species.push(...response.data.docs);
      } else this.species = [];
    }
  }
};
</script>
