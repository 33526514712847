<template>
  <v-card flat title>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              label="SOURCE"
              :items="sources"
              :rules="rule"
              v-model="source"
              :open-on-clear="false"
              :disabled="sources.length < 2"
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              label="YEAR"
              :items="years"
              :rules="rule"
              v-model="year"
              :open-on-clear="false"
              hide-selected
              hide-details
              outlined
              dense
              clearable
              @click:clear="data = []"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn
              block
              color="primary"
              @click="getData"
              :disabled="loading.data || loading.export"
              :loading="loading.data"
              v-text="'Submit'"
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn
              block
              color="primary"
              class="d-none"
              @click="exportData"
              :disabled="loading.data || loading.export"
              :loading="loading.export"
              v-text="'Export'"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12" lg="6" xl="4" v-for="item in data" :key="item.name">
          <v-card elevation="4">
            <v-card-title class="justify-center" v-text="item.name" />
            <v-card-text>
              <bar-chart :chart-data="item.chartData" :options="options" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import BarChart from './BarChart.vue';
import { getFile } from '../../helpers/format';
import { notifyMessage } from '../../helpers/notifications';
import { RECORD_TYPES, SOURCE_TYPES } from '../../helpers/constants';
export default {
  components: { BarChart },
  name: 'report-board',
  data: () => ({
    year: null,
    source: 'TBF',
    rule: [(v) => !!v || 'Required'],
    chartData: { labels: [], datasets: [] },
    data: [],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      }
    },
    loading: {
      data: false,
      export: false
    }
  }),
  computed: {
    events() {
      return RECORD_TYPES.map((v) => v.toUpperCase());
    },
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    years() {
      const years = [];
      for (let year = 2012; year <= moment().year(); year++) {
        years.push(year);
      }
      return years.reverse();
    }
  },
  methods: {
    ...mapActions('analytic', ['getBoardReports']),
    async getData() {
      this.data = [];

      const valid = this.$refs.form && this.$refs.form.validate();
      if (valid) {
        this.loading.data = true;
        const response = await this.getBoardReports({
          year: this.year,
          source: this.source
        });
        const { docs } = response?.data || {};

        for (const key of Object.keys(docs || {})) {
          const name = String(key).toUpperCase();

          // if (!docs[key]?.length) {
          //   continue;
          // }

          if (
            this.source !== 'TBF' &&
            (name === 'USERS' || name === 'MEMBERS')
          ) {
            continue;
          }

          if (
            !this.events.includes(name) &&
            name !== 'USERS' &&
            name !== 'MEMBERS'
          ) {
            continue;
          }

          const chartData = {
            labels: docs[key]?.map((item) => item.Month),
            datasets: [
              {
                label: name,
                backgroundColor: '#2196F3',
                data: docs[key]?.map((item) => item.Total)
              }
            ]
          };

          this.data.push({ name, chartData });
        }

        // this.data = Object.keys(docs || {}).map((doc) => {
        //   const name = String(doc).toUpperCase();

        //   const chartData = {
        //     labels: docs[doc]?.map((item) => item.Month),
        //     datasets: [
        //       {
        //         label: name,
        //         backgroundColor: '#2196F3',
        //         data: docs[doc]?.map((item) => item.Total)
        //       }
        //     ]
        //   };
        //   return { name, chartData };
        // });
        this.loading.data = false;
      }
    },
    async exportData(format = 'xlsx') {
      this.loading.export = true;
      const response = await this.getBoardReports({
        year: this.year,
        export: true
      });
      const { docs } = response?.data || {};
      this.loading.export = false;
      if (!docs) {
        notifyMessage('Unable to export the requested data');
        return;
      }
      getFile(docs, format, 'Report Board');
    }
  }
};
</script>
