<template>
  <div>
    <v-container fluid>
      <v-form ref="recapturesCompleted" :disabled="loading">
        <v-row class="my-0">
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-autocomplete
              label="SOURCE"
              :items="sources"
              :rules="rule"
              v-model="filter.source"
              :open-on-clear="false"
              :disabled="sources.length < 2"
              outlined
              dense
              clearable
              hide-details
            />
          </v-col>
          <v-spacer />
        </v-row>
        <v-row class="my-0">
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-autocomplete
              label="Species"
              :items="species"
              v-model="filter.specie"
              item-text="name"
              item-value="name"
              :search-input.sync="searchSpecies"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              label="Prior Tag Number"
              v-model="filter.priorNumber"
              :rules="tagRule"
              v-mask="'BF######'"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-text-field
              label="New Tag Number"
              v-model="filter.number"
              :rules="tagRule"
              v-mask="'BF######'"
              hide-details
              clearable
              dense
              outlined
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <v-bottom-sheet v-model="sheet" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  block
                  v-bind="attrs"
                  v-on="on"
                  @click="launchMap"
                  :disabled="loading"
                >
                  Location
                  <v-icon right>mdi-map-search</v-icon>
                </v-btn>
              </template>
              <v-sheet class="text-center" height="500px">
                <v-toolbar color="primary" flat dark>
                  <v-btn text dark @click="closeMap"> Close </v-btn>
                  <v-spacer />
                  <v-toolbar-title>Select Quadrant</v-toolbar-title>
                  <v-spacer />
                  <v-btn text dark @click="sheet = false"> Apply </v-btn>
                </v-toolbar>
                <div class="py-3">
                  <fishing-locations
                    ref="fishingLocations"
                    @coords="setQuadrant"
                    :fabPosition="false"
                  />
                </div>
              </v-sheet>
            </v-bottom-sheet>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3">
            <auto-complete-users
              label="Original Angler"
              v-model="filter.originalAngler"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <auto-complete-users
              label="Original Captain"
              v-model="filter.originalCaptain"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <auto-complete-users
              label="Recapture Angler"
              v-model="filter.recaptureAngler"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <auto-complete-users
              label="Recapture Captain"
              v-model="filter.recaptureCaptain"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <date-picker
              v-model="filter.date[0]"
              label="START DATE"
              @clear="filter.date = [null, null]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <date-picker
              v-model="filter.date[1]"
              label="END DATE"
              :minDate="filter.date[0]"
              :disable="!filter.date[0]"
            />
          </v-col>

          <v-col cols="12" sm="6" :md="hideReset ? 4 : 2" lg="3">
            <v-btn
              block
              color="primary"
              @click="applyFilter"
              :disabled="loading"
              >Apply</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" md="2" lg="3" v-if="!hideReset">
            <v-btn block color="red" dark @click="reset" :disabled="loading"
              >Clear</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <list-view
      ref="listView"
      class="mt-5"
      :title="'Recaptures completed'"
      :headers="headers"
      :records="tableItems"
      :async-loading="loading"
      :footer-options="footer"
      :total-docs="totalDocs"
      :hideHeader="true"
      @onPaginate="(v) => (options = v)"
      exportable
      @export="(v) => exportFile(v)"
      scrollable
    >
      <template slot="list-item" slot-scope="{ row }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              depressed
              x-small
              color="blue-grey"
              dark
              v-bind="attrs"
              v-on="on"
              @click="presentRecords(row)"
            >
              <v-icon> mdi-map-marker-distance </v-icon>
            </v-btn>
          </template>
          <span>Show Recapture</span>
        </v-tooltip>
      </template>
      <template slot="header" slot-scope="{ row }" v-if="totalDocs">
        <th
          class="text-left pl-5 pb-5 header_size"
          v-for="(head, i) in row.headers"
          :key="i"
        >
          <div
            :class="
              !head['sort'] ? 'd-flex head_div' : 'd-flex head_div min-width'
            "
          >
            <span
              class="caption font-weight-bold grey--text text--darken-2 pr-1"
            >
              {{ head.text }}
            </span>
            <v-btn icon v-if="!!head['sort']" @click="sort(head)" x-small>
              <v-icon v-if="head['order']">mdi-arrow-down</v-icon>
              <v-icon v-else>mdi-arrow-up</v-icon>
            </v-btn>
          </div>
        </th>
      </template>
    </list-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import ListView from '../../components/appComponents/ListView.vue';
import FishingLocations from '../map/FishingLocations.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';
import { SOURCE_TYPES, getFile } from '../../helpers/handler';
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
export default {
  components: { ListView, FishingLocations, DatePicker, AutoCompleteUsers },
  name: 'pairs-list',
  mounted() {
    this.getSpecies();
  },
  data: () => ({
    tableItems: [],
    footer: {
      pageCount: 0
    },
    headers: Headers,
    loading: false,
    totalDocs: 0,
    species: [],
    search: null,
    rule: [(v) => !!v || 'Required'],
    filter: _.cloneDeep(initFilter),
    sheet: false,
    options: null,
    freeToCall: false,
    order: false
  }),
  computed: {
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    searchSpecies: {
      get: function () {
        return this.search;
      },
      set: function (v) {
        if (v !== this.search) {
          this.search = v;
          this.getSpecies(v);
        }
      }
    },
    hideReset() {
      return _.isEqual(this.filter, initFilter);
    },
    tagRule() {
      return [(v) => !v || v?.length >= 8 || 'Minimum 6 digits required'];
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    ...mapActions('analytic', ['getRecapturesCompleted']),
    sort(head) {
      this.headers.forEach((item) => {
        if (item.sort === head?.sort) {
          item.order = !head?.order;
        } else {
          item.order = true;
        }
      });
      const header = this.headers.find((item) => item?.sort === head?.sort);
      this.filter.sort = header.sort;
      this.order = header.order;
      // this.filter.sort = head?.sort;
      // this.order = !this.order;
      this.freeToCall = true;
      this.getRecaptures();
    },
    async getRecaptures() {
      const { page, itemsPerPage: limit } = this.options || {};
      if (this.freeToCall && page && limit) {
        this.loading = true;
        try {
          const response = await this.getRecapturesCompleted({
            page,
            limit,
            ...this.filter,
            order: this.order ? 1 : 2
          });
          if (response?.data) {
            const { docs, totalPages, page, totalDocs } = response.data || {};
            this.tableItems = docs || [];
            this.footer.pageCount = totalPages || 0;
            this.footer.page = page || 0;
            this.totalDocs = totalDocs || 0;
          }
          this.loading = false;
        } catch {
          this.loading = false;
        }
      }
    },
    presentRecords(item) {
      this.$emit('present', item);
    },
    getArray(data) {
      return Object.keys(data || {}).map((doc) => ({
        name: data[doc],
        abbr: doc
      }));
    },
    async getSpecies(name = '') {
      const payload = { name, item: 'species', page: 1, limit: 100 };
      const response = await this.getItems(payload);
      const docs = response?.data?.docs;
      this.species = docs && Array.isArray(docs) ? docs : this.getArray(docs);
    },
    reset() {
      this.filter = _.cloneDeep(initFilter);
      this.$refs.recapturesCompleted && this.$refs.recapturesCompleted.reset();
      this.applyFilter();
    },
    launchMap() {
      setTimeout(() => {
        this.$refs.fishingLocations && this.$refs.fishingLocations.draw();
      }, 100);
    },
    setQuadrant(data) {
      this.filter.location = data;
    },
    closeMap() {
      this.$refs.fishingLocations && this.$refs.fishingLocations.clear();
      this.sheet = false;
    },
    async exportFile(format) {
      this.$refs.listView && (this.$refs.listView.loadingExport = true);
      const { data } =
        (await this.getRecapturesCompleted({
          page: this.options?.page,
          limit: this.options?.itemsPerPage,
          ...this.filter,
          order: this.order ? 1 : 2,
          export: true,
          format
        })) || {};
      getFile(data?.docs, format, 'Recaptures completed');
      this.$refs.listView && (this.$refs.listView.loadingExport = false);
    },
    applyFilter() {
      const valid =
        this.$refs.recapturesCompleted &&
        this.$refs.recapturesCompleted.validate();
      if (valid) {
        !this.freeToCall && (this.freeToCall = true);
        this.$refs.listView && this.$refs.listView.resetParams();
      }
    }
  },
  watch: {
    options: {
      handler: 'getRecaptures'
    }
  }
};

const initFilter = {
  source: 'TBF',
  specie: undefined,
  number: undefined,
  priorNumber: undefined,
  originalAngler: undefined,
  originalCaptain: undefined,
  recaptureAngler: undefined,
  recaptureCaptain: undefined,
  location: undefined,
  date: [null, null],
  sort: null
};

const Headers = [
  { text: '', value: 'actions', sortable: false },
  // {
  //   text: 'ID',
  //   align: 'start',
  //   sortable: false,
  //   value: 'id'
  // },
  {
    text: 'New Tag Number',
    align: 'start',
    sortable: false,
    value: 'New Tag Number',
    sort: 'number',
    order: true
  },
  {
    text: 'Prior Tag Number',
    align: 'start',
    sortable: false,
    value: 'Prior Tag Number',
    sort: 'priorNumber',
    order: true
  },
  {
    text: 'Species',
    align: 'start',
    sortable: false,
    value: 'Species',
    sort: 'species',
    order: true
  },
  {
    text: 'Tag Date      ',
    align: 'start',
    sortable: false,
    value: 'Date',
    sort: 'tagDate',
    order: true,
    width: 200
  },
  {
    text: 'Recapture Date',
    align: 'start',
    sortable: false,
    value: 'Recapture Date',
    sort: 'recaptureDate',
    order: true
  },
  {
    text: 'Days At Large',
    align: 'start',
    sortable: false,
    value: 'Days At Large',
    sort: 'days',
    order: true
  },
  {
    text: 'Years At Large',
    align: 'start',
    sortable: false,
    value: 'Years At Large',
    sort: 'years',
    order: true
  },
  {
    text: 'Distance In Miles',
    align: 'start',
    sortable: false,
    value: 'Distance In Miles',
    sort: 'distance',
    order: true
  },
  {
    text: 'Recapture Angler',
    align: 'start',
    sortable: false,
    value: 'Recapture angler',
    sort: 'recaptureAngler',
    order: true
  },
  {
    text: 'Recapture Captain',
    align: 'start',
    sortable: false,
    value: 'Recapture captain',
    sort: 'recaptureCaptain',
    order: true
  },
  {
    text: 'Original Angler',
    align: 'start',
    sortable: false,
    value: 'Original angler',
    sort: 'originalAngler',
    order: true
  },
  {
    text: 'Original Captain',
    align: 'start',
    sortable: false,
    value: 'Original captain',
    sort: 'originalCaptain',
    order: true
  },
  {
    text: 'Weight',
    align: 'start',
    sortable: false,
    value: 'Weight',
    sort: 'weight',
    order: true
  },
  {
    text: 'Length',
    align: 'start',
    sortable: false,
    value: 'Length',
    sort: 'length',
    order: true
  },
  {
    text: 'Boat',
    align: 'start',
    sortable: false,
    value: 'Boat',
    sort: 'boat',
    order: true
  },
  {
    text: 'Location',
    align: 'start',
    sortable: false,
    value: 'Location',
    sort: 'location',
    order: true
  }
];
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.head_div {
  width: max-content;
}

.min-width {
  min-width: 120px;
}
</style>
