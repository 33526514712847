<template>
  <v-container fluid>
    <v-form ref="events">
      <v-row>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="SOURCE"
            :items="sources"
            :rules="rule"
            v-model="source"
            :open-on-clear="false"
            :disabled="sources.length < 2"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="SPECIES"
            :items="items.species"
            v-model="specie"
            item-text="name"
            item-value="name"
            :search-input.sync="searchSpecies"
            v-on:change="clearChart"
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="EVENT"
            :items="events"
            v-model="event"
            :open-on-clear="false"
            v-on:change="clearChart"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="OCEANS"
            :items="items.oceans"
            v-model="ocean"
            item-text="name"
            item-value="acronym"
            :search-input.sync="searchOceans"
            v-on:change="clearChart"
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" sm="4">
          <date-picker
            v-model="date[0]"
            v-on:change="clearChart"
            label="START DATE"
            @clear="date = [null, null]"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <date-picker
            v-model="date[1]"
            v-on:change="clearChart"
            label="END DATE"
            :minDate="date[0]"
            :disable="!date[0]"
          />
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-btn block color="primary" @click="getReport">Apply</v-btn>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <v-btn
            block
            color="primary"
            :disabled="!chartData.labels.length"
            @click="exportFile('excel')"
          >
            Export
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <h3 class="mb-5">Group by</h3>
          <v-radio-group v-model="groupBy" row :rules="rule" dense>
            <v-radio label="Species" value="species"></v-radio>
            <v-radio label="Oceans" value="oceans"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="chartData.labels.length" class="text-center">
      <pie-chart :chart-data="chartData" :options="options" />
      <v-chip class="mt-5 pr-0 font-weight-bold" v-if="total">
        Total:
        <v-chip color="primary" class="ml-1"> {{ total }} </v-chip>
      </v-chip>
    </div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import PieChart from './PieChart.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';
import { notifyError, notifyMessage } from '../../helpers/notifications';
import { getFile } from '../../helpers/format';
import { RECORD_TYPES, SOURCE_TYPES } from '../../helpers/constants';
export default {
  components: { PieChart, DatePicker },
  name: 'fish-amount',
  mounted() {
    this.getFilterItems('species');
    this.getFilterItems('oceans');
  },
  data: () => ({
    items: {
      species: [],
      oceans: []
    },
    specie: null,
    ocean: null,
    year: null,
    event: null,
    source: 'TBF',
    groupBy: 'species',
    rule: [(v) => !!v || 'Required'],
    search: {
      species: null,
      oceans: null
    },
    chartData: { labels: [], datasets: [] },
    options: null,
    date: [null, null],
    total: 0,
    overlay: false
  }),
  computed: {
    events() {
      return RECORD_TYPES.map((v) => v.toUpperCase());
    },
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    searchOceans: {
      get: function () {
        return this.search.oceans;
      },
      set: function (v) {
        if (v !== this.search.oceans) {
          this.search.oceans = v;
          this.getFilterItems('oceans', v);
        }
      }
    },
    searchSpecies: {
      get: function () {
        return this.search.species;
      },
      set: function (v) {
        if (v !== this.search.species) {
          this.search.species = v;
          this.getFilterItems('species', v);
        }
      }
    },
    years() {
      const years = [];
      for (let year = 1990; year <= moment().year(); year++) {
        years.push(year);
      }
      return years.reverse();
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    ...mapActions('analytic', ['getFishStatistic']),
    getArray(data) {
      return Object.keys(data || {}).map((doc) => ({
        name: data[doc],
        abbr: doc
      }));
    },
    async getFilterItems(item, name = '') {
      const payload = {
        name,
        item,
        page: 1,
        limit: item === 'species' ? 100 : 10
      };
      const response = await this.getItems(payload);
      const docs = response?.data?.docs;
      if (docs) {
        Array.isArray(docs) && docs?.length
          ? (this.items[item] = docs)
          : (this.items[item] = this.getArray(docs));
      }
    },
    clearChart() {
      this.chartData = { labels: [], datasets: [] };
      this.options = null;
    },
    async exportFile(format = null) {
      this.overlay = true;

      try {
        const response = await this.getFishStatistic({
          specie: this.specie,
          event: this.event,
          date: this.date,
          year: this.year,
          ocean: this.ocean,
          groupBy: this.groupBy,
          export: format,
          source: this.source
        });

        const { success, base64 } = response?.data?.docs || {};

        if (success) {
          getFile(base64, format, 'Records summary');
        } else {
          notifyError('Unable to fetch the requested data');
        }
      } catch (error) {
        notifyError('Unable to fetch the requested data');
      }

      this.overlay = false;
    },
    async getReport() {
      const valid = this.$refs.events && this.$refs.events.validate();
      if (valid) {
        this.overlay = true;

        try {
          const response = await this.getFishStatistic({
            specie: this.specie,
            event: this.event,
            date: this.date,
            year: this.year,
            ocean: this.ocean,
            groupBy: this.groupBy,
            source: this.source
          });
          this.total = 0;

          const { docs } = response?.data || {};
          if (docs) {
            this.total = docs['TOTAL'];

            if (!this.total) {
              notifyMessage('No results');
            }

            const validData = Object.keys(docs || {}).filter(
              (key) => key !== 'TOTAL'
            );
            const labels = validData.map((key) => `${key} (${docs[key]})`);
            const data = validData.map((key) => docs[key]);

            this.options = {
              responsive: true,
              maintainAspectRatio: false,
              height: 300
            };
            this.chartData = {
              labels,
              datasets: [
                {
                  label: '',
                  backgroundColor: [
                    '#2196F3',
                    '#4CAF50',
                    '#FF9800',
                    '#9C27B0',
                    '#009688',
                    '#CDDC39',
                    '#FFEB3B',
                    '#795548',
                    '#607D8B',
                    '#E91E63'
                  ],
                  data
                }
              ]
            };
          } else this.clearChart();
        } catch (error) {
          notifyError('Unable to fetch the requested data');
        }

        this.overlay = false;
      }
    }
  }
};
</script>
