<template>
  <v-card flat title>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="12" sm="4" md="3">
            <v-autocomplete
              label="YEAR"
              :items="years"
              :rules="rule"
              v-model="year"
              :open-on-clear="false"
              hide-selected
              hide-details
              outlined
              dense
              clearable
            />
          </v-col>
          <v-col cols="12" sm="4" md="3">
            <v-btn block color="primary" @click="getData">Submit</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { notifyMessage } from '../../helpers/handler';
export default {
  name: 'iccat',
  data: () => ({
    overlay: false,
    year: null,
    rule: [v => !!v || 'Required']
  }),
  computed: {
    years() {
      const years = [];
      for (let year = 2012; year <= moment().year(); year++) {
        years.push(year);
      }
      return years.reverse();
    }
  },
  methods: {
    ...mapActions('query', ['getICCAT']),
    async getData() {
      const valid = this.$refs.form && this.$refs.form.validate();
      if (valid) {
        this.overlay = true;
        const response = await this.getICCAT({ year: this.year });

        if (response?.statusText === 'OK') {
          const blob = new Blob([this.s2ab(atob(response?.data))], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'
          });

          const a = document.createElement('a');
          a.href = URL.createObjectURL(blob);
          a.download = `TBF_ICCAT_${this.year}.xlsx`;
          a.click();
          this.$emit('cancel');
        } else notifyMessage('Something went wrong');
        this.overlay = false;
      }
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
  }
};
</script>
