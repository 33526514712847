<template>
  <v-container fluid>
    <v-form ref="events">
      <v-row>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="SOURCE"
            :items="sources"
            :rules="rule"
            v-model="source"
            :open-on-clear="false"
            :disabled="sources.length < 2"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="Event"
            :items="events"
            v-model="event"
            :rules="rule"
            :open-on-clear="false"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="YEAR"
            :items="years"
            :rules="rule"
            v-model="year"
            :open-on-clear="false"
            hide-selected
            hide-details
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="Species"
            :items="items.species"
            v-model="specie"
            item-text="name"
            item-value="acronym"
            :rules="rule"
            :search-input.sync="searchSpecies"
            @click:clear="clearChart"
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
            label="Oceans"
            :items="items.oceans"
            v-model="ocean"
            item-text="name"
            item-value="acronym"
            :rules="rule"
            :search-input.sync="searchOceans"
            @click:clear="clearChart"
            clearable
            dense
            outlined
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn block color="primary" @click="getReport">Apply</v-btn>
        </v-col>
        <v-col cols="12">
          <h3 class="mb-5">Group by</h3>
          <v-radio-group v-model="groupBy" row :rules="rule" dense>
            <v-radio
              label="Species"
              value="species"
              :disabled="specie !== 'ALL'"
            ></v-radio>
            <v-radio
              label="Oceans"
              value="oceans"
              :disabled="ocean !== 'ALL'"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <div v-if="chartData.labels.length">
      <pie-chart :chart-data="chartData" :options="options" />
    </div>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import PieChart from './PieChart.vue';
import { RECORD_TYPES, SOURCE_TYPES } from '../../helpers/constants';
export default {
  components: { PieChart },
  name: 'events',
  mounted() {
    this.getFilterItems('species');
    this.getFilterItems('oceans');
  },
  data: () => ({
    items: {
      species: [],
      oceans: []
    },
    specie: 'ALL',
    ocean: 'ALL',
    year: null,
    event: null,
    groupBy: 'species',
    rule: [(v) => !!v || 'Required'],
    search: {
      species: null,
      oceans: null
    },
    chartData: { labels: [], datasets: [] },
    options: null,
    source: 'TBF'
  }),
  computed: {
    events() {
      return RECORD_TYPES.map((v) => v.toUpperCase());
    },
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    searchOceans: {
      get: function () {
        return this.search.oceans;
      },
      set: function (v) {
        if (v !== this.search.oceans) {
          this.search.oceans = v;
          this.getFilterItems('oceans', v);
        }
      }
    },
    searchSpecies: {
      get: function () {
        return this.search.species;
      },
      set: function (v) {
        if (v !== this.search.species) {
          this.search.species = v;
          this.getFilterItems('species', v);
        }
      }
    },
    years() {
      const years = [];
      for (let year = 1990; year <= moment().year(); year++) {
        years.push(year);
      }
      return years.reverse();
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    ...mapActions('analytic', ['getEvents']),
    getArray(data) {
      return Object.keys(data || {}).map((doc) => ({
        name: data[doc],
        abbr: doc
      }));
    },
    async getFilterItems(item, name = '') {
      const payload = {
        name,
        item,
        page: 1,
        limit: item === 'species' ? 100 : 10
      };
      const response = await this.getItems(payload);
      const docs = response?.data?.docs;
      if (docs) {
        Array.isArray(docs) && docs?.length
          ? (this.items[item] = docs)
          : (this.items[item] = this.getArray(docs));
        this.items[item].push({ name: 'ALL', acronym: 'ALL' });
        this.items[item] = this.items[item].reverse();
      }
    },
    clearChart() {
      this.chartData = { labels: [], datasets: [] };
      this.options = null;
    },
    async getReport() {
      const valid = this.$refs.events && this.$refs.events.validate();
      if (valid) {
        const response = await this.getEvents({
          specie: this.specie,
          event: this.event,
          year: this.year,
          ocean: this.ocean,
          groupBy: this.groupBy,
          source: this.source
        });

        if (response && response?.data && response.data?.docs) {
          const labels = Object.keys(response.data.docs || {}).map((v) => {
            return v !== 'null'
              ? `${v} (${response.data.docs[v]})`
              : 'Undefined';
          });
          const data = Object.values(response.data.docs || {}).map((v) => v);

          this.options = {
            responsive: true,
            maintainAspectRatio: false,
            height: 300
          };
          this.chartData = {
            labels,
            datasets: [
              {
                label: '',
                backgroundColor: [
                  '#2196F3',
                  '#4CAF50',
                  '#FF9800',
                  '#9C27B0',
                  '#009688',
                  '#CDDC39',
                  '#FFEB3B',
                  '#795548',
                  '#607D8B',
                  '#E91E63'
                ],
                data
              }
            ]
          };
        } else this.clearChart();
      }
    }
  }
};
</script>
