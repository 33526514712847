<template>
  <v-card>
    <v-toolbar color="blue-grey lighten-5">
      <v-btn icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Recapture Record View</v-toolbar-title>
      <v-spacer />
      <v-btn color="primary" rounded @click="getScreenshot" :loading="loading"
        >Get Screenshot</v-btn
      >
    </v-toolbar>
    <v-card-text>
      <div ref="pairContent">
        <v-row class="my-2">
          <v-col cols="12" sm="6" v-for="(item, i) in data" :key="i">
            <v-expansion-panels v-model="closeAll[i]" multiple flat>
              <v-expansion-panel>
                <v-expansion-panel-header :class="item.color">
                  <span class="white--text">{{ item.name }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-5">
                  <p v-if="item['Prior Tag Number']">
                    <span class="font-weight-thin"> Prior Tag Number: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Prior Tag Number']"
                    />
                  </p>
                  <p v-if="item['Tag Number'] || item['New Tag Number']">
                    <span
                      class="font-weight-thin"
                      v-text="
                        `${item['New Tag Number'] ? 'New ' : ''}Tag Number: `
                      "
                    />
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Tag Number'] || item['New Tag Number']"
                    />
                  </p>
                  <p>
                    <span
                      class="font-weight-thin"
                      v-text="
                        `${
                          item['Prior Tag Number'] ? 'Recapture' : 'Tag'
                        } Date: `
                      "
                    />
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="
                        formatDate(item['Created Date']) || 'Not Specified'
                      "
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Angler: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="getName(item['Angler']) || 'Not Specified'"
                    />
                    <v-menu right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-if="!!getName(item['Angler'])"
                        >
                          <v-icon small> mdi-information </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in getTooltip(item['Angler'])"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.subtitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                  <p>
                    <span class="font-weight-thin"> Boat: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Boat'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Captain: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="getName(item['Captain']) || 'Not Specified'"
                    />
                    <v-menu right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-if="!!getName(item['Captain'])"
                        >
                          <v-icon small> mdi-information </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in getTooltip(item['Captain'])"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.subtitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                  <p>
                    <span class="font-weight-thin"> First Mate: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="getName(item['First Mate']) || 'Not Specified'"
                    />
                    <v-menu right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-if="!!getName(item['First Mate'])"
                        >
                          <v-icon small> mdi-information </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in getTooltip(
                            item['First Mate']
                          )"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.subtitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                  <p>
                    <span class="font-weight-thin"> Second Mate: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="getName(item['Second Mate']) || 'Not Specified'"
                    />
                    <v-menu right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          v-if="!!getName(item['Second Mate'])"
                        >
                          <v-icon small> mdi-information </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in getTooltip(
                            item['Second Mate']
                          )"
                          :key="index"
                        >
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ item.title }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.subtitle }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </p>
                  <p>
                    <span class="font-weight-thin"> Location: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Location']"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Species: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Species'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Gear: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Gear'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Bait: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Bait'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Fish Condition: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Fish Condition'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Fish Length: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Fish Length'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Fish Weight: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Fish Weight'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Fight Time: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Fight Time'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Ocean: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Ocean'] || 'Not Specified'"
                    />
                  </p>
                  <p>
                    <span class="font-weight-thin"> Event: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Event'] || 'Not Specified'"
                    />
                  </p>
                  <p v-if="item['Days At Large']">
                    <span class="font-weight-thin"> Days At Large: </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Days At Large']"
                    />
                  </p>
                  <p v-if="item['Distance Traveled']">
                    <span class="font-weight-thin">
                      Distance Traveled (mi):
                    </span>
                    <span
                      class="font-weight-black text-uppercase"
                      v-text="item['Distance Traveled']"
                    />
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <fishing-locations
          ref="location"
          :fabPosition="false"
          :showLogo="loading"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import FishingLocations from '../map/FishingLocations.vue';
import * as html2canvas from 'html2canvas';
import { getImg, fixTimezone, formatDate } from '@/helpers/handler';
import moment from 'moment';
import { getAddress } from '../../helpers/user';
export default {
  components: { FishingLocations },
  name: 'pair',
  data: () => ({
    tag: null,
    recapture: null,
    data: [],
    closeAll: {
      0: [],
      1: []
    },
    loading: false
  }),
  methods: {
    init(data) {
      this.closeAll = { 0: [], 1: [] };
      const { Tag, Recapture } = data || {};
      this.data = [
        { ...(Tag || {}), name: 'Tag', color: 'blue' },
        {
          ...(Recapture || {}),
          name: 'Recapture',
          color: 'orange'
        }
      ];
      this.$refs.location &&
        this.$refs.location.addPair(Recapture?.Coordinates, Tag?.Coordinates);
    },
    getName(user) {
      const { firstName, lastName } = user || {};

      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }

      return firstName || lastName || null;
    },
    getTooltip(user) {
      const { email, legacyId, memberId, memberExpiration } = user || {};
      const fixedDate = fixTimezone(memberExpiration);
      const address = getAddress(user);

      return [
        { title: 'Email', subtitle: email || '-' },
        { title: 'Legacy ID', subtitle: legacyId || '-' },
        { title: 'Member ID', subtitle: memberId || '-' },
        {
          title: 'Membership Expiration',
          subtitle: formatDate(fixedDate) || '-'
        },
        { title: 'Address', subtitle: address || '-' }
      ];
    },
    close() {
      this.$emit('close');
    },
    getScreenshot() {
      this.loading = true;
      const closeAll = this.closeAll;
      this.closeAll = { 0: [0], 1: [0] };
      setTimeout(async () => {
        try {
          if (this.$refs.pairContent) {
            const output = await html2canvas(this.$refs.pairContent, {
              useCORS: true,
              onrendered: function (canvas) {
                document.body.appendChild(canvas);
              }
            });
            if (output) {
              const url = output.toDataURL();
              const title = `RecaptureDaysAtLarge_${this.data[1]['Prior Tag Number']}`;
              getImg(url, title);
            }
          }
        } catch (error) {
          console.log(error);
        }
        this.closeAll = closeAll;
        this.loading = false;
      }, 1500);
    },
    formatDate(value) {
      const fixedDate = fixTimezone(value);
      const date = moment(fixedDate || null);
      return date.isValid() ? date.format('MM/DD/YYYY') : '';
    }
  }
};
</script>
