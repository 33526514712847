<template>
  <v-card>
    <v-dialog v-model="openMap" max-width="960px" scrollable :fullscreen="full">
      <pair ref="pair" @close="openMap = false" />
    </v-dialog>
    <v-toolbar flat>
      <template>
        <v-tabs
          v-model="tab"
          align-with-title
          background-color="blue-grey lighten-5"
          color="blue-grey"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          show-arrows
          fixed-tabs
        >
          <v-tabs-slider color="blue-grey"></v-tabs-slider>
          <v-tab v-for="item in reports" :key="item" v-text="item" />
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <report-board />
        </v-tab-item>
        <v-tab-item>
          <fish-conditions />
        </v-tab-item>
        <v-tab-item>
          <fish-amount />
        </v-tab-item>
        <v-tab-item>
          <summary-by-year />
        </v-tab-item>
        <v-tab-item>
          <events />
        </v-tab-item>
        <v-tab-item>
          <pairs @present="presentRecords" />
        </v-tab-item>
        <v-tab-item>
          <iccat />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import Pair from './Pair.vue';
import Pairs from './Pairs.vue';
import FishConditions from './FishConditions.vue';
import FishAmount from './FishAmount.vue';
import SummaryByYear from './SummaryByYear.vue';
import Events from './Events.vue';
import Iccat from './iccat.vue';
import ReportBoard from './ReportBoard.vue';
export default {
  components: {
    Pair,
    Pairs,
    FishConditions,
    SummaryByYear,
    Events,
    Iccat,
    ReportBoard,
    FishAmount
  },
  name: 'analytics',
  data: () => ({
    reports: Reports,
    tab: 0,
    openMap: false
  }),
  computed: {
    full() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    presentRecords(item) {
      this.openMap = true;
      setTimeout(() => {
        this.$refs.pair && this.$refs.pair.init(item);
      }, 50);
    }
  }
};

const Reports = [
  'Report Board',
  'Fish conditions',
  'Total Fish by Species/Ocean/Event',
  'Summary by year',
  'Events by Ocean/Species/Year',
  'Recapture Days at Large',
  'ICCAT'
];
</script>
