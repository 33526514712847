<template>
  <v-container fluid>
    <v-form ref="summaryByYear">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <date-picker
            v-model="date[0]"
            label="START DATE"
            :rules="[(v) => !!v || 'Required']"
            @clear="date = [null, null]"
          />
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <date-picker
            v-model="date[1]"
            label="END DATE"
            :minDate="date[0]"
            :disable="!date[0]"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            label="SOURCE"
            :items="sources"
            :rules="rule"
            v-model="source"
            :open-on-clear="false"
            :disabled="sources.length < 2"
            outlined
            dense
            clearable
          />
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn block color="primary" @click="getReport" :loading="loading"
            >Apply</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <div v-if="chartData.labels.length">
      <v-tabs v-model="tab">
        <v-tab v-text="'Chart'" />
        <v-tab v-text="'Data'" />
      </v-tabs>
      <v-tabs-items v-model="tab" class="mt-5">
        <v-tab-item>
          <bar-chart :chart-data="chartData" :options="options" />
        </v-tab-item>
        <v-tab-item>
          <data-iterator :keys="keys" :items="tableItems" :sortBy="keys[0]" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import DataIterator from '../../components/appComponents/DataIterator.vue';
import BarChart from './BarChart.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';
import { RECORD_TYPES, SOURCE_TYPES } from '../../helpers/constants';
export default {
  components: { BarChart, DataIterator, DatePicker },
  name: 'summary-by-year',
  data: () => ({
    date: [null, null],
    chartData: { labels: [], datasets: [] },
    options: null,
    rule: [(v) => !!v || 'Required'],
    tab: 0,
    loading: false,
    source: 'TBF'
  }),
  computed: {
    events() {
      return RECORD_TYPES.map((v) => v.toUpperCase());
    },
    sources() {
      if (SOURCE_TYPES.length < 2) {
        return SOURCE_TYPES;
      }

      return [...SOURCE_TYPES.map((v) => v.toUpperCase()), 'ALL'];
    },
    keys() {
      return ['Year', ...RECORD_TYPES];
    },
    tableItems() {
      let items = [];
      this.chartData.datasets?.forEach((item) => {
        items.push({
          name: item.label,
          tags: item.data[0],
          releases: item.data[1],
          recaptures: item.data[2],
          juveniles: item.data[3]
        });
      });
      return items;
    }
  },
  methods: {
    ...mapActions('analytic', ['getYearSummary']),
    clearChart() {
      this.chartData = { labels: [], datasets: [] };
      this.options = null;
    },
    async getReport() {
      const valid =
        this.$refs.summaryByYear && this.$refs.summaryByYear.validate();
      if (valid) {
        this.loading = true;
        const response = await this.getYearSummary({
          range: this.date,
          source: this.source
        });
        if (response?.data && response.data?.docs) {
          this.options = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            }
          };
          const datasets = Object.keys(response.data.docs)
            .map((key) => {
              if (key !== 'unknown') {
                const { tags, releases, recaptures, juveniles } =
                  response.data.docs[key];
                return {
                  label: key,
                  backgroundColor: ['#2196F3', '#4CAF50', '#FF9800', '#4B0082'],
                  data: [tags, releases, recaptures, juveniles]
                };
              }

              return null;
            })
            .filter(Boolean);
          this.chartData = {
            labels: this.events,
            datasets
          };
        } else this.clearChart();
        this.loading = false;
      }
    }
  }
};
</script>
