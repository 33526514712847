<template>
  <v-card>
    <v-dialog
      v-model="openHelp"
      max-width="800px"
      :fullscreen="mobileView"
      scrollable
    >
      <v-card class="elevation-4">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <v-icon class="pr-2">mdi-tooltip-question</v-icon>
            How the map feature works
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="openHelp = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <h3>Set a marker</h3>
          <p>
            To pin a location in the map or set a marker, you need to click on
            the map area and a marker/pin will be set. If you want to lock the
            coordinates of the selected location you just need to click on the
            "Click to select location" pop up.
          </p>

          <h3>Set your current location</h3>
          <p>
            To set your current location you can click on the "Set Current"
            button at the top right of the map area.
          </p>

          <h3>Modify the location</h3>
          <p>
            To modify the coordinates or the pinned location, you can click on
            the "Enable Navigation" button at the bottom left of the map area.
            That will allow you to scroll/zoom and move across the map, and find
            the wanted location.
          </p>

          <h3>Remove the pin or location marker</h3>
          <p>
            To remove the pin/marker you can click on the "Clear Marker" button
            at the top right of the map area.
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="fab"
      max-width="1200px"
      :fullscreen="mobileView"
      scrollable
    >
      <v-card class="elevation-4">
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            <v-icon class="pr-2">mdi-map-legend</v-icon>
            Select the base map
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="fab = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="6" md="3" v-for="layer in layers" :key="layer">
              <fishing-locations
                ref="baseMapLayers"
                :fabPosition="false"
                :fabNavigation="false"
                :base-map="layer"
                :zoom-control="false"
                class="map-preview"
              />
              <v-btn
                @click="
                  setBasemap(layer);
                  fab = false;
                "
                class="btn-fix leaflet-control"
                block
                tile
              >
                SELECT {{ layer }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
      striped
      height="5"
    ></v-progress-linear>
    <div id="map" ref="fishingMap">
      <div class="leaflet-bottom leaflet-right">
        <v-avatar size="128" v-if="showLogo">
          <img src="/img/billfish-logo-new.png" alt="TBF" />
        </v-avatar>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabBaseMap">
              <v-btn
                fab
                @click.stop="fab = true"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary"> mdi-map-legend </v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> Select the base map </span>
        </v-tooltip>
      </div>
      <div class="leaflet-bottom leaflet-left">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabNavigation">
              <v-btn
                fab
                @click.stop="toggleNavigation"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary">
                  {{ enabled ? 'mdi-cancel' : 'mdi-map-check' }}
                </v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>
            {{ enabled ? 'Disable Navigation' : 'Enable Navigation' }}
          </span>
        </v-tooltip>
      </div>
      <div class="leaflet-top leaflet-right">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabPosition">
              <v-btn
                fab
                @click.stop="setCurrent"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
                :disable="locating"
              >
                <v-icon color="primary">mdi-map-marker-radius</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> Set Current </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabPosition">
              <v-btn
                fab
                @click.stop="clearMarker"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary">mdi-map-marker-remove-variant</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span> Clear Marker </span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition v-if="fabPosition">
              <v-btn
                fab
                @click.stop="openHelp = true"
                class="btn-fix leaflet-control"
                v-bind="attrs"
                v-on="!mobileView ? on : null"
              >
                <v-icon color="primary">mdi-map-marker-question</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>Help</span>
        </v-tooltip>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  handleCoords,
  validCoordinates,
  notifyMessage,
  WATER_SURFACES,
  reverseCoords,
  getMarkerContent,
  markerIconImg,
  markerShadowImg
} from '../../helpers/handler';
import { CoordinatesViewModel } from '../users/viewmodels/index';
export default {
  name: 'fishing-locations',
  props: {
    draggable: {
      type: Boolean,
      default: false
    },
    fabPosition: {
      type: Boolean,
      default: true
    },
    fabNavigation: {
      type: Boolean,
      default: true
    },
    input: {
      type: Object,
      default: () => new CoordinatesViewModel()
    },
    fabBaseMap: {
      type: Boolean,
      default: () => false
    },
    baseMap: {
      type: String,
      default: 'Topographic'
    },
    zoomControl: {
      type: Boolean,
      default: () => true
    },
    showLogo: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    map: null,
    enabled: false,
    coords: null,
    marker: null,
    markers: null,
    locating: false,
    terrainType: null,
    openHelp: false,
    loading: false,
    layers: LAYERS,
    fab: false
  }),
  mounted() {
    if (!this.map) {
      this.initMap();
    }
  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    LatLng() {
      const { coordinates } = this.input || {};
      const valid = validCoordinates(coordinates);

      if (!valid) {
        return JSON.parse(localStorage.getItem('location')) || [0, 0];
      }

      return handleCoords(coordinates);
    },
    data: {
      set: function (data) {
        const { coordinates } = data || {};
        const valid = validCoordinates(coordinates);
        if (valid) {
          this.$emit('coords', coordinates);
        }
      },
      get: function () {
        return this.input;
      }
    },
    recaptureIcon() {
      return new window.L.Icon({
        iconUrl: markerIconImg,
        shadowUrl: markerShadowImg,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
      });
    }
  },
  watch: {
    'data.coordinates': {
      handler: 'handleData'
    },
    enabled: {
      handler: 'setHandlerState'
    },
    map: {
      handler: 'setHandlerState'
    },
    terrainType: {
      handler: 'emitTerrainType',
      immediate: true
    }
  },
  methods: {
    ...mapActions('query', ['getCrewLocations']),
    setBasemap(basemap = 'Topographic') {
      window.L.esri.basemapLayer(basemap).addTo(this.map);
    },
    handleData(coordinates) {
      this.initMap();
      this.clearLayers();

      if (this.marker) {
        this.map.removeLayer(this.marker);
      }

      const valid = validCoordinates(coordinates);

      if (!valid) {
        this.newMarker();
        return;
      }

      this.addMarker();
    },
    newMarker() {
      this.map.on('click', ({ latlng: LatLng }) => {
        if (this.marker) {
          this.map.removeLayer(this.marker);
        }

        this.marker = window.L.marker(LatLng).addTo(this.map);
        this.marker = this.setClickablePopUp(this.marker);
        this.coords = LatLng;
        this.$emit('coords', reverseCoords(LatLng));
        this.marker.on('dragend', ({ target: marker }) => {
          const { lat, lng } = marker.getLatLng();
          this.$emit('coords', reverseCoords({ lat, lng }));
        });
      });
    },
    addMarker() {
      const content = getMarkerContent(this.data);

      const popUp = new window.L.Popup();
      const [lat, lng] = this.LatLng;
      popUp.setLatLng(new window.L.LatLng(lat, lng));
      popUp.setContent(content);

      this.marker = window.L.marker(this.LatLng).addTo(this.map);
      this.marker.bindPopup(popUp);
      this.map.setView(this.LatLng, 4);

      if (this.enabled) {
        this.marker.dragging.enable();
      }

      this.marker.on('dragend', ({ target: marker }) => {
        const { lat, lng } = marker.getLatLng();
        this.$emit('coords', reverseCoords({ lat, lng }));
      });
    },
    setClickablePopUp(marker) {
      if (!marker) {
        return marker;
      }

      let btn = document.createElement('button');
      btn.innerText = 'Click to select location';
      btn.onclick = (event) => {
        if ('preventDefault' in event) {
          event.preventDefault();
        }

        marker.closePopup();

        if (this.coords) {
          this.$emit('coords', reverseCoords(this.coords));
        }
      };
      marker.bindPopup(btn, { closeButton: false, maxWidth: 'auto' });
      marker.openPopup();
      return marker;
    },
    clearLocationMarkers() {
      if (this.markers && 'clearLayers' in this.markers) {
        this.markers.clearLayers();
      }
    },
    async setLocationMarkers(users) {
      if (!users || !users.length) {
        return;
      }

      this.loading = true;

      if (!this.map) {
        this.initMap();
      }

      const payload = { users };
      const validCoords = validCoordinates(this.data.coordinates);
      if (validCoords) {
        payload['coordinates'] = this.data.coordinates;
      }

      try {
        const response = await this.getCrewLocations(payload);
        const { docs } = response?.data || {};

        if (!docs || !docs.length) {
          this.loading = false;
          return;
        }

        this.clearLocationMarkers();

        this.markers = window.L.markerClusterGroup({
          iconCreateFunction: function (cluster) {
            return new window.L.DivIcon({
              html: '<div><span>' + cluster.getChildCount() + '</span></div>',
              className: 'marker-cluster',
              iconSize: new window.L.Point(40, 40)
            });
          }
        });

        for (const doc of docs) {
          const { coordinates } = doc || {};
          const valid = validCoordinates(coordinates);

          if (!valid) {
            continue;
          }

          const coords = handleCoords(coordinates);

          if (!coords) {
            continue;
          }

          const marker = window.L.marker(coords, { icon: this.recaptureIcon });
          this.markers.addLayer(marker);
        }

        this.map.addLayer(this.markers);
        if (!validCoords) {
          this.map.fitBounds(this.markers.getBounds(), {
            padding: window.L.point(35, 35)
          });
        }
      } catch (error) {
        console.log('Error setting location markers', error);
      }

      this.loading = false;
    },
    close() {
      if (!this.map) {
        return;
      }

      if (this.marker) {
        this.map.removeLayer(this.marker);
        this.marker = null;
      }

      this.map.off();
      this.map.remove();
      this.map = null;
    },
    initMap() {
      if (this.map) {
        return;
      }
      this.map = new window.L.Map(this.$refs.fishingMap, {
        dragging: false,
        tap: false,
        scrollWheelZoom: false,
        zoomControl: this.zoomControl
      });
      this.map.options.maxZoom = 15;
      window.L.DomUtil.addClass(
        this.map._container,
        'crosshair-cursor-enabled'
      );
      window.L.esri.basemapLayer(this.baseMap).addTo(this.map);
      setTimeout(() => {
        this.map.setView(this.LatLng, 4);
      }, 50);
      this.enabled = false;
    },
    async setCurrent() {
      if (!('geolocation' in navigator)) {
        notifyMessage('Geolocation is not supported by this device');
        return;
      }

      const permission = await navigator.permissions.query({
        name: 'geolocation'
      });

      if (permission.state === 'denied') {
        notifyMessage('Allow Geolocation permission');
        return;
      }

      const location = localStorage.getItem('location');
      if (!location) {
        this.getLocation();
        return;
      }

      const [lat, lng] = JSON.parse(location);
      this.$emit('coords', reverseCoords({ lat, lng }));
    },
    getLocation() {
      this.locating = true;
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        this.locating = false;
        const { latitude, longitude } = coords || {};
        localStorage.setItem('location', JSON.stringify([latitude, longitude]));
        this.$emit('coords', reverseCoords({ lat: latitude, lng: longitude }));
      });
    },
    getDescription(coordinates) {
      if (!coordinates || !coordinates['description']) {
        return;
      }

      const latlng = handleCoords(coordinates);

      const geocoder = window.L.esri.Geocoding.geocodeService({
        apikey:
          'AAPKde199e1968134b64a2c7ae9cd7d2aa13RqAB5ly-c2MylgUNnFHbQQJx12gF_uGlff0j3AfmPwwPaF36YXWLZLzUALO9Vgck'
      });

      geocoder
        .reverse()
        .latlng(latlng)
        .run((error, result) => {
          this.terrainType = result.address['Type'];
        });
    },
    emitTerrainType(value) {
      if (value === null || value === undefined) {
        return;
      }

      const isWater = WATER_SURFACES.some((surface) => surface === value);

      if (!isWater) {
        this.$emit('validate', true);
      }

      this.$emit('validate', isWater);
    },
    setHandlerState() {
      if (!this.map) {
        return;
      }

      if (this.enabled) {
        document.getElementById('map').style.cursor = 'grab';
        this.map._handlers.forEach(
          (handler) => 'enable' in handler && handler.enable()
        );
      } else {
        document.getElementById('map').style.cursor = 'default';
        this.map._handlers.forEach(
          (handler) => 'disable' in handler && handler.disable()
        );
      }

      if (!this.marker) {
        return;
      }

      const { dragging } = this.marker;

      if (this.enabled) {
        if ('enable' in dragging) {
          dragging.enable();
        }
      } else {
        if ('disable' in dragging) {
          dragging.disable();
        }
      }
    },
    toggleNavigation(event) {
      if ('isTrusted' in event && !event['isTrusted']) {
        return;
      }

      this.enabled = !this.enabled;
    },
    addPair(coord1, coord2) {
      this.initMap();
      if (this.markers && 'clearLayers' in this.markers) {
        this.markers.clearLayers();
      }

      this.markers = window.L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          return new window.L.DivIcon({
            html: '<div><span>' + cluster.getChildCount() + '</span></div>',
            className: 'marker-cluster',
            iconSize: new window.L.Point(40, 40)
          });
        }
      });

      const LatLng1 = handleCoords(coord1);

      if (LatLng1) {
        const popUp1 = new window.L.Popup().setLatLng(LatLng1).setContent(`
        <b> - Recapture - </b> <br />
        <b> Latitude: </b> ${LatLng1[0]} <br/>
        <b> Longitude: </b> ${LatLng1[1]} <br/>
        `);

        this.markers.addLayer(
          window.L.marker(LatLng1, {
            icon: this.recaptureIcon
          }).bindPopup(popUp1)
        );
      }

      const LatLng2 = handleCoords(coord2);

      if (LatLng2) {
        const popUp2 = new window.L.Popup().setLatLng(LatLng2).setContent(`
        <b> - Tag - </b> <br />
        <b> Latitude: </b> ${LatLng2[0]} <br/>
        <b> Longitude: </b> ${LatLng2[1]} <br/>
        `);

        this.markers.addLayer(window.L.marker(LatLng2).bindPopup(popUp2));
      }

      this.map.addLayer(this.markers);
      this.map.fitBounds(this.markers.getBounds(), {
        padding: window.L.point(35, 35)
      });
    },
    draw() {
      if (!this.map || !('selectArea' in this.map)) {
        this.initMap();
      }

      setTimeout(async () => {
        if (!this.map.pm) {
          window.L.PM.reInitLayer(this.map);
        }

        this.map.pm.addControls({
          position: 'topleft',
          drawCircleMarker: false,
          drawMarker: false,
          drawCircle: false,
          drawPolyline: false,
          drawText: false,
          cutPolygon: false
        });

        const retrieveCoords = (layer) => {
          const { geometry } = layer.toGeoJSON();
          const bounds = layer.getBounds();
          this.$emit('coords', {
            NE: reverseCoords(bounds._northEast),
            SW: reverseCoords(bounds._southWest)
          });

          if (geometry?.coordinates?.length) {
            const [coords] = geometry.coordinates;
            this.$emit('polygon', coords);
          }

          layer.on('pm:remove', () => this.clear());
        };

        this.map.on('pm:create', ({ layer }) => {
          const savedLayers = this.map.pm.getGeomanLayers();
          if (savedLayers?.length > 1) {
            const [previousLayer] = savedLayers;
            this.map.removeLayer(previousLayer);
          }

          retrieveCoords(layer);
          layer.on('pm:edit', ({ layer }) => retrieveCoords(layer));
        });
      }, 60);
    },
    clearLayers() {
      const { _layers } = this.map || {};
      Object.values(_layers || {}).forEach(async (layer) => {
        if (layer.options?.color) {
          try {
            this.map.removeLayer(layer);
          } catch (e) {
            console.log('problem with ' + e + layer);
          }
        }
      });
    },
    clearMarker() {
      if (this.marker) {
        this.map.removeLayer(this.marker);
      }
      const { coordinates } = new CoordinatesViewModel();
      this.$emit('coords', coordinates);
      this.initMap();
    },
    clear() {
      this.clearLayers();
      const { coordinates } = new CoordinatesViewModel();
      delete coordinates.description;
      this.$emit('coords', {
        NE: { ...coordinates },
        SW: { ...coordinates }
      });
      this.$emit('polygon', null);
    }
  },
  beforeDestroy() {
    localStorage.removeItem('location');
    this.close();
  }
};

const LAYERS = [
  'Streets',
  'Topographic',
  'NationalGeographic',
  'Oceans',
  'Gray',
  'DarkGray',
  'Imagery',
  'ImageryClarity',
  'ImageryFirefly',
  'ShadedRelief',
  'Terrain',
  'Physical'
];
</script>

<style>
.leaflet-container.crosshair-cursor-enabled {
  cursor: default;
}

#map {
  height: 400px;
  z-index: 0;
}

.map-preview #map {
  height: 200px;
}

.marker-cluster {
  background: rgba(94, 94, 218, 0.8);
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: 700;
  border: 5px solid rgba(94, 94, 218);
}

.marker-cluster div {
  padding-top: 20%;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}
</style>
